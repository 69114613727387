var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v1.45.1"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import CONFIG from '@config';
import * as Sentry from '@sentry/nextjs';

const GITHUB_REF = process.env.NEXT_PUBLIC_GITHUB_REF;
const GITHUB_SHA = process.env.NEXT_PUBLIC_GITHUB_SHA;
const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

const fixSentryRelease = (ref) => {
  try {
    return ref.split('/').pop();
  } catch (e) {
    return ref;
  }
};


Sentry.init({ 
  dsn: CONFIG.sentry?.store?.dsn, 
  environment: CONFIG.sentry?.store?.environment || 'unknown',
  release: fixSentryRelease(GITHUB_REF) || GITHUB_SHA,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.2,
  beforeSend: (event, hint) => {
    if (SENTRY_ENABLED) {
      return event;
    } else {
      console.error(hint.originalException || hint.syntheticException);
      return null;
    }
  }
});
